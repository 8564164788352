import React from "react";
import SEO from "components/seo";
import useHerosTypesData from "shared/Hooks/PageHeaders/useHerosTypesData";
import { BigHero } from "components/BigHero";
import { OFFER_ECOMMERCE } from "shared/Types/heros";
import { OfferLayoutNew } from "components/OfferLayoutNew";
import { data_eCommerce } from "../../data/offer/ecommerce";
import { useDesignOfferCase } from "shared/Hooks/CasesNew/useDesignOfferCase";

const Ecommerce = () => {
  const data = useHerosTypesData(OFFER_ECOMMERCE);
  const data_cases = useDesignOfferCase();
  const data_projects = Object.values(data_cases);

  return (
    <>
      <SEO title={data.title} description={data.seo} />
      <BigHero data={data} />
      <OfferLayoutNew data={data_eCommerce} data_projects={data_projects} />
    </>
  );
};

export default Ecommerce;
