import React from "react";
import { Link } from "gatsby";

export const data_eCommerce = {
  what_we_do: {
    title: "Dlaczego warto uruchomić sklep internetowy?",
    text: [
      <>
        <b>Dużo większa skala działania</b> - posiadanie sklepu online daje Ci
        możliwość funkcjonowania na wielu płaszczyznach. Sprawimy, że nie
        będziesz musiał ograniczać swojej działalności.
      </>,
      <>
        <b>Łatwość w dotarciu do docelowej grupy odbiorców</b> - przy pomocy
        e-sklepu nawiązanie relacji z kupującymi i zbudowanie społeczności
        zainteresowanej Twoimi produktami jest dużo prostsze.
      </>,
      <>
        <b>Możliwość skalowania</b> - kiedy poczujesz, że nadszedł moment
        poszerzenia swojej działalności lub skali sprzedaży, sklep internetowy
        Ci to umożliwi, a podstawowe koszty operacyjne się nie zwiększą.
      </>,
      <>
        <b>Łatwość w dotarciu na nowe rynki</b> - Internet to rynek, w którym
        jest miejsce dla wszystkich. Dlatego w przypadku sklepów internetowych
        wkroczenie do nowej strefy sprzedażowej nie jest tak trudne, jak w
        przypadku sklepów stacjonarnych.
      </>,
    ],
  },
  our_process: {
    title: "Nasz proces projektowy",
    steps: [
      {
        title: "Planowanie",
        list: [
          "Opracowanie briefu",
          "Audyt obecnych zasobów",
          "Analiza konkurencyjnych rozwiązań",
          "Dobór technologii do założeń projektu",
        ],
      },
      {
        title: "Projektowanie graficzne",
        list: [
          "Koncepcja funkcjonalna",
          "Opracowanie Makiet",
          "Projektowanie Widoków",
          <Link to="/uslugi/web-design">
            Zobacz szczegółowy proces projektowania graficznego
          </Link>,
        ],
      },
      {
        title: "Wdrożenie",
        list: [
          "Dobór usługi hostingowej do zastosowanej technologii",
          "Utworzenie repozytorium plików",
          "Utworzenie struktury projektu",
          "Współpraca przy UX/UI",
          "Programowanie",
          "Współpraca przy treściach oraz SEO",
          "Wprowadzanie produktów",
        ],
      },
      {
        title: "Obsługa",
        list: [
          "Aktualizacje systemu",
          "Wdrożenie udoskonaleń funkcjonalnych",
          "Tworzenie kopii bezpieczeństwa",
          "Monitorowanie działania systemu",
        ],
      },
    ],
  },
};
